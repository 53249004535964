import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const Room = ({ location, page }) => {
  const hotel = {
    '@context': 'https://schema.org',
    '@type': 'Course',
    name: page.title,
    description: page._rawDescription[0].children[0].text,
    provider: {
      '@type': 'Organization',
      name: 'Soul Valley',
      sameAs: 'https://soulvalley.com',
    },
    hasCourseInstance: [
      {
        offers: {
          '@type': 'Offer',
          price: page.price,
          priceCurrency: 'EUR',
        },
      },
    ],
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(hotel) }}
    />
  )
}

export default Room

Room.propTypes = {
  location: PropTypes.object,
  page: PropTypes.object,
}
